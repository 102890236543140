// Element-groups
@mixin group($offset-x, $offset-y: $offset-x) {
	margin-bottom: -$offset-y;
	margin-left: -$offset-x;

	&:empty {
		margin-bottom: 0;
		margin-left: 0;
	}

	> * {
		display: inline-block;
		margin: 0 0 $offset-y $offset-x;
	}
}

@mixin group-media($offset-x, $offset-y: $offset-x) {
	margin-bottom: -$offset-y;
	margin-left: -$offset-x;

	> * {
		margin-bottom: $offset-y;
		margin-left: $offset-x;
	}
}

@mixin groupX($offset-x) {
	margin-left: -$offset-x;
	> * { margin-left: $offset-x; }
}

@mixin groupY($offset-y) {
	margin-bottom: -$offset-y;
	> * { margin-bottom: $offset-y; }
}
