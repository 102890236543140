//
// Grid-dedicated responsive offset system
//

@mixin grid-offset($offset) {
	margin-bottom: -$offset;

	&:empty { margin-bottom: 0; }
	> * { margin-bottom: $offset; }
}
