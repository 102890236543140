/*
* @subsection Google Map
* @description Describes style declarations for RD Google Map extension
* @version     1.0.0
*/

.google-map-markers{
	display: none;
}

.google-map-container {
	width: 100%;
}
.google-map {
	height: $map-height;
	width: 100%;

	@include media-breakpoint-up(sm) {
		height: $map-xs-height;
	}

	@include media-breakpoint-up(md) {
		height: $map-md-height;
	}

	@include media-breakpoint-up(lg) {
		height: $map-lg-height;
	}

	@include media-breakpoint-up(xl) {
		height: $map-xl-height;
	}
}

.gm-style-iw > div > div {
	padding-right: 10px;
	font-size: 13px;
}

// Vertical align
.google-map-align {
	display: flex;
	min-height: 330px;
	height: auto;

	.google-map { height: auto; }
}
