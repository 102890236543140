/*
*
* Slick Carousel
*/

//
// Base styles
//
/*rtl:begin:ignore*/

$slick-font-path: "./fonts/" !default;
$slick-font-family: 'fl-bigmug-line';
$slick-loader-path: "./" !default;
$slick-arrow-color: $gray-800;
$slick-dot-color: $primary;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\e05b" !default;
$slick-next-character: "\e097" !default;
$slick-dot-character: "";
$slick-dot-size: 6px !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: .75 !default;
$slick-opacity-not-active: 0.25 !default;

.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;

	&:focus {
		outline: none;
	}

	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;

	&::before,
	&::after {
		content: "";
		display: table;
	}

	&::after {
		clear: both;
	}

	.slick-loading & {
		visibility: hidden;
	}
}

.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;
	[dir="rtl"] & {
		float: right;
	}
	img {
		display: block;
	}
	&.slick-loading img {
		display: none;
	}

	display: none;

	&.dragging img {
		pointer-events: none;
	}

	.slick-initialized & {
		display: block;
	}

	.slick-loading & {
		visibility: hidden;
	}

	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}

.slick-arrow.slick-hidden {
	display: none;
}

@function slick-image-url($url) {
	@if function-exists(image-url) {
		@return image-url($url);
	} @else {
		@return url($slick-loader-path + $url);
	}
}

@function slick-font-url($url) {
	@if function-exists(font-url) {
		@return font-url($url);
	} @else {
		@return url($slick-font-path + $url);
	}
}

// Appearance styles
// --------------------------------------------------

.slick-list {
	.slick-loading & {
		background: $white slick-image-url("../images/ajax-loader.gif") center center no-repeat;
	}
}

/* Icons */
@if $slick-font-family == "slick" {
	@font-face {
		font-family: "slick";
		src: slick-font-url("slick.eot");
		src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
		font-weight: normal;
		font-style: normal;
	}
}

/* Arrows */

.slick-prev,
.slick-next {
	position: absolute;
	display: block;
	height: 20px;
	width: 20px;
	line-height: 0;
	font-size: 0;
	cursor: pointer;
	color: transparent;
	background-color: transparent;
	top: 50%;
	transform: translate(0, -50%);
	padding: 0;
	border: none;
	outline: none;
	z-index: 9;
	
	&.slick-disabled:before {
		opacity: $slick-opacity-not-active;
	}
	
	&::before {
		font-family: $slick-font-family;
		font-size: 24px;
		line-height: 1;
		color: $slick-arrow-color;
		opacity: $slick-opacity-default;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		transition: .22s;
	}
	
	&:hover {
		&::before {
			opacity: $slick-opacity-on-hover;
			color: $primary-darken;
		}
	}
}

.slick-prev {
	left: 0;
	[dir="rtl"] & {
		left: auto;
		right: 0;
	}
	&:before {
		content: $slick-prev-character;
		[dir="rtl"] & {
			content: $slick-next-character;
		}
	}
}

.slick-next {
	right: 0;
	[dir="rtl"] & {
		left: 0;
		right: auto;
	}
	&:before {
		content: $slick-next-character;
		[dir="rtl"] & {
			content: $slick-prev-character;
		}
	}
}

/* Dots */

.slick-slider {
	
}

.slick-dots {
	position: absolute;
	bottom: -45px;
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	width: 100%;
	li {
		position: relative;
		display: inline-block;
		height: 20px;
		width: 20px;
		margin: 0 5px;
		padding: 0;
		cursor: pointer;
		button {
			border: 0;
			background: transparent;
			display: block;
			height: 20px;
			width: 20px;
			outline: none;
			line-height: 0;
			font-size: 0;
			color: transparent;
			padding: 5px;
			cursor: pointer;
			&:hover, &:focus {
				outline: none;
				&:before {
					opacity: $slick-opacity-on-hover;
				}
			}
			
			&:before {
				position: absolute;
				top: 0;
				left: 0;
				content: $slick-dot-character;
				font-family: $slick-font-family;
				font-size: $slick-dot-size;
				line-height: 20px;
				text-align: center;
				color: $slick-dot-color;
				opacity: $slick-opacity-not-active;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
		}
		
		&.slick-active button:before {
			color: $slick-dot-color-active;
			opacity: $slick-opacity-default;
		}
	}
}


/*rtl:end:ignore*/

// Custom styles
.slick-widget-testimonials {
	.carousel-child {
		max-width: 570px;
		padding: 0 50px;
		margin-left: auto;
		margin-right: auto;
		
		.item {
			border-radius: 50%;
			cursor: pointer;
			transform: scale3d(.66, .66, .66);
			transition: .22s;
			text-align: center;
		}
		
		.slick-center {
			transform: scale3d(1, 1, 1);
		}
		
		img {
			border-radius: inherit;
			max-width: 100%;
			margin: 0 auto;
		}
		
		
		@include media-breakpoint-down(xs) {
		  .slick-prev {
				left: 30px;
			} 
			
			.slick-next {
				right: 30px;
			}
		}
		
	}
	
	.carousel-parent {
		max-width: 780px;
		margin-left: auto;
		margin-right: auto;
		
		.item > * {
			transform-origin: 50% 0;
			opacity: 0;
			transition: .44s;
			
		}
		
		.slick-active > * {
			opacity: 1;
		}
	}
	
	> * + * { margin-top: 30px; }
}

* + .slick-widget-testimonials { margin-top: 30px; }

@include media-breakpoint-up(md) {
	* + .slick-widget-testimonials { margin-top: 55px; } 
}

@include media-breakpoint-up(xl) {
	* + .slick-widget-testimonials { margin-top: 75px; } 
} 
