/*
*
* Tooltop Custom
*/

//
// Base class
//

.tooltip {
	font-size: $font-size-base;
	line-height: 1.2;
}

.tooltip-inner {
	background-color: $tooltip-bg;
}

.bs-tooltip-top {
	.arrow::before {
		border-top-color: $tooltip-arrow-color;
	}
}

.bs-tooltip-right {
	.arrow::before {
		border-right-color: $tooltip-arrow-color;
	}
}

.bs-tooltip-bottom {
	.arrow::before {
		border-bottom-color: $tooltip-arrow-color;
	}
}

.bs-tooltip-left { 
	.arrow::before {
		border-left-color: $tooltip-arrow-color;
	}
}

@include media-breakpoint-up(xl) {
	.tooltip-inner {
		padding: 9px 18px;
	}
} 
