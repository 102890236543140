/**
*
* RD Navbar Minimal
* 
*/
.rd-navbar-minimal {
	// Static
	&.rd-navbar-static {
		border-bottom: 1px solid transparent;

		.rd-navbar-main-outer {
			padding-left: $grid-gutter-width / 2;
			padding-right: $grid-gutter-width / 2;
		}

		.rd-navbar-main {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			max-width: $rd-navbar-static-width;
			padding: 20px 0;
			margin-left: auto;
			margin-right: auto;
		}

		.rd-navbar-main-element {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			min-width: 690px;
		}

		// RD Navbar Brand
		.rd-navbar-brand {
			img {
				width: auto;
				height: auto;
				max-width: 200px;
				max-height: 200px;
			}
		}

		.rd-navbar-megamenu {
			margin-top: 24px;
			width: calc(100% + #{nth($rd-navbar-menu-padding, 2)} + 15px);
		}

		// RD Navbar Search
		.rd-navbar-search {}

		.rd-navbar-search-toggle {
			span {
				font-size: 20px;
			}
		}

		@include media-breakpoint-up(xl) {
			.rd-navbar-main {
				padding: 30px 0;
			}

			.rd-menu {
				margin-top: 34px;
			}
		}

		&.rd-navbar--is-stuck,
		&.rd-navbar--is-clone {
			border-bottom-color: nth($rd-navbar-border, 3);

			.rd-navbar-main {
				padding: 20px 0;
			}

			.rd-menu {
				margin-top: 24px;
			}

			.rd-navbar-nav-item {
				> .rd-navbar-submenu {
					margin-top: 17px;
				}
			}
		}
	}

	// Fixed 
	&.rd-navbar-fixed {
		.rd-navbar-search-toggle {
			position: fixed;
			right: 10px;
			top: 5px;
		}

		.rd-navbar-search {
			.form-input {
				border-width: 1px 0 1px 0;
				border-radius: 0;
			}
		}
	}
}

.rd-navbar-minimal-wide {
	&.rd-navbar-static {
		.rd-navbar-main {
			padding: 54px 0;
		}

		&.rd-navbar--is-stuck {
			.rd-navbar-main {
				padding: 21px 0;
			}
		}
	}
}

%context-dark {
	.rd-navbar-minimal {
		.rd-nav-link,
		.rd-navbar-submenu-toggle,
		.rd-navbar-search-toggle {
			color: $white;
		}
		
		&.rd-navbar-static {
			background-color: transparent;

			.rd-nav-item.opened .rd-nav-link,
			.rd-nav-item.focus .rd-nav-link,
			.rd-nav-item.active .rd-nav-link,
			.rd-nav-item.opened .rd-navbar-submenu-toggle,
			.rd-nav-item.focus .rd-navbar-submenu-toggle,
			.rd-nav-item.active .rd-navbar-submenu-toggle,
			.rd-nav-link:hover,
			.rd-nav-link:hover ~ .rd-navbar-submenu-toggle,
			.rd-navbar-search-toggle:hover {
				color: lighten($primary, 20%);
			}
			
			&.rd-navbar--is-stuck {
				background: $gray-700;
				border-bottom-color: $gray-800;
			}
		}
	}
}
