//
// Mixins
// --------------------------------------------------

// Clearfix
// 
@mixin clearfix() {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

// Disable padding - top on two similar backgrounds
// 
@mixin bg-behaviour($bg-color) {
	background-color: $bg-color;
}

// Grid related values
//

@function grid-parameter($breakpoint, $col, $offset: 0) {
	@if ($col == 6) {
		@return 50%;
	} @else  { 
		@if $offset > 0 {
			@return calc(50% - #{map-get($container-max-widths, $breakpoint) / 2  - (map-get($container-max-widths, $breakpoint) / $grid-columns) * $col} + #{$offset});
		} @else if ($offset < 0) {
			@return calc(50% - #{map-get($container-max-widths, $breakpoint) / 2 - (map-get($container-max-widths, $breakpoint) / $grid-columns) * $col} - #{-$offset});
		} @else {
			@return calc(50% - #{map-get($container-max-widths, $breakpoint) / 2 - (map-get($container-max-widths, $breakpoint) / $grid-columns) * $col});
		}
	}
}


// Link universal mixin
//
@mixin link($default-clr, $hover-color) {
	&,
	&:active,
	&:focus {
		color: $default-clr;
	}

	&:hover {
		color: $hover-color;
	}
}
